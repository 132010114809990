'use strict'

angular.module('cb.staff-service', [])
    .factory('StaffService', ["ApiService", function (ApiService) {

        var staffService = {
            getAllStaff: function (includeLeaving = false) {
				try {
					var url = '/webapi/api/v1/staff/getAll/' + includeLeaving;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			getEnquiryHandlers: function (includeLeaving = false) {
				try {
					var url = '/webapi/api/v1/staff/getEnquiryHandlers/' + includeLeaving;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
            getStaffByRole: function (role) {
                try {
                    var data = { RoleFilter : role };
                    var url = '/webapi/api/v1/staff/getByRole';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            getlistOfRolesForStaff: function () {
                try {
                    var url = '/webapi/api/v1/staff/GetlistOfRolesForStaff';
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            createStaff: function (data) {
                try {
                    var url = '/webapi/api/v1/staff/createStaff';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            editStaff: function (data) {
                try {
                    var url = '/webapi/api/v1/staff/editStaff';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            activateStaff: function (id) {
                try {
                    var url = "/webapi/api/v1/user/" + id + "/activate";
                    return ApiService.promisePost(url, {});
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            deactivateStaff: function (id) {
                try {
                    var url = "/webapi/api/v1/user/" + id + "/deactivate";
                    return ApiService.promisePost(url, {});
                } catch (e) {
                    console.error('Error!', e);
                }
            }
        };

        return staffService;
    }]);
